















import Vue from 'vue';
import { date } from 'quasar';

export default Vue.extend({
  name: 'Enquiry-Messsage-Component',
  props: {
    message: {
      type: Object,
      required: true,
      default: () => {
        return {
          message: '',
          received: false,
          info: {
            from: '',
          },
          meta: {
            createdOn: '',
          },
          failedToSend: false,
        };
      },
    },
    person: {
      type: Object,
      default: () => {
        return {
          email: '',
          firstname: '',
          surname: '',
        };
      },
      required: true,
    },
  },
  computed: {
    prettyFrom (): string {
      let payload: string = '';
      if (this.message.received) {
        payload = this.person.firstname + ' ' + this.person.surname;
        payload += ' <' + this.person.email + '>';
      } else {
        payload = this.person.firstname + ' ' + this.person.surname;
      }
      return payload;
    },
  },
  created () {
    // If sent message, strip out empty divs to make message better
    if (!this.message.received) {
      const regexEmptyLines: RegExp = new RegExp(/(<div><br\s\/><\/div>)/gm);
      this.message.message = this.message.message.replace(regexEmptyLines, '');
    }
  },
  methods: {
    getPrettyDate (value: string) {
      return date.formatDate(new Date(value), 'HH:mm MMMM Do YYYY');
    },
  },
});
