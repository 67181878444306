







































































import Vue from 'vue';
import Close from '@/blueprint/components/ui/CloseButton.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';

import { LandlordState } from '@/store/landlord/state';
import { Enquiry, EnquiryMessage as IEnquiryMessage } from '@lordly/models2/interfaces/models/Message';
import { API } from '@lordly/models2/interfaces/gql';

export default Vue.extend({
  name: 'Enquiry-Reply-Component',
  components: {
    'close-btn': Close,
  },
  props: {
    enquiry: {
      type: Object as () => Partial<Enquiry>,
      required: true,
      default: () => {
        return {
          id: '',
          contact: {
            firstname: '',
            surname: '',
            email: '',
          },
          latest_message: '',
          meta: {
            viewed: true,
            lastUpdatedOn: new Date().toISOString(),
            obsolete: false,
          },
        };
      },
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => {
    return {
      form: {
        message: '<div></div>',
      },
      replyInProgress: false,
    };
  },
  computed: {
    landlordFullname (): string {
      const landlordState: LandlordState = this.$store.state.landlord;
      return landlordState.user.firstname + ' ' + landlordState.user.surname;
    },
    contactTo (): string {
      if (this.enquiry) {
        return this.enquiry.contact!.firstname + ' ' + this.enquiry.contact!.surname + ' <' + this.enquiry.contact!.email + '>';
      }
      return '';
    },
    contactFirstname (): string {
      return this.enquiry.contact!.firstname;
    },
    subject (): string {
      return this.contactFirstname + '; Enquiry response from Lordly.app - ' + this.enquiry.id;
    },
  },
  watch: {
    enquiry: {
      handler () {
        this.updateForm();
      },
      deep: true,
    },
  },
  mounted () {
   this.updateForm();
  },
  methods: {
    /**
     * Capture the <CTL-V> paste event, only allow plain-text, no images.
     *
     * see: https://stackoverflow.com/a/28213320
     *
     * @param {object} evt - array of files
     * @author Daniel Thompson-Yvetot
     * @license MIT
     */
    pasteCapture (evt: any) {
      let text: string = '';
      let onPasteStripFormattingIEPaste: boolean = false;
      const editor: Vue & { runCmd: (arg1: string, arg2: string) => void } = this.$refs['message'] as Vue & { runCmd: (arg1: string, arg2: string) => void };
      evt.preventDefault();
      if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
        text = evt.originalEvent.clipboardData.getData('text/plain');
        editor.runCmd('insertText', text);
      } else if (evt.clipboardData && evt.clipboardData.getData) {
        text = evt.clipboardData.getData('text/plain');
        editor.runCmd('insertText', text);
      } else if ((window as any).clipboardData && (window as any).clipboardData.getData) {
        if (!onPasteStripFormattingIEPaste) {
          onPasteStripFormattingIEPaste = true;
          editor.runCmd('ms-pasteTextOnly', text);
        }
        onPasteStripFormattingIEPaste = false;
      }
    },
    validateReply () {
      // Validate - Required, Min length and max length
      if (this.form.message && this.form.message.length > 0) {
        if (this.form.message.length >= 10) {
          if (this.form.message.length <= 2500) {
            // Test if original message in place
            const regexOriginalMessage: RegExp = new RegExp(/(\[YOUR MESSAGE\])/gm);
            const regexMatches: RegExpMatchArray | null = this.form.message.match(regexOriginalMessage);
            if (regexMatches === null || regexMatches.length === 0) {
              this.submitReply(this.form.message);
            } else {
              this.notifyError('Please update the template message');
            }
          } else {
            this.notifyError('Please use less than 2500 characters');
          }
        } else {
          this.notifyError('Please use more than 10 characters');
        }
      } else {
        this.notifyError('A message is required');
      }
    },
    async submitReply (replyMsg: string) {
      this.replyInProgress = true;
      // Create query
      const query: GQLTagRequestObject = gql`
        mutation ($id: String!, $partition: String!, $message: String!) {
          RespondToEnquiry (
            input: {
              id: $id,
              partition: $partition,
              message: $message
            }
          ) {
            id
            history {
              message
              received
              info {
                from
              }
              meta {
                createdOn
              }
              failedToSend
            }
          }
        }
      `;
      // Create query payload
      const payload: API.RespondToEnquiryInput = {
        id: this.enquiry.id!,
        partition: (this.$store.state.landlord as LandlordState).user.subscription,
        message: replyMsg,
      };
      // Send query
      try {
        const response: Partial<Enquiry> = await this.$gql.Mutation('RespondToEnquiry', query, payload);
        if (response) {
          // Reset message
          this.form.message = '';
          // Emit history
          this.$emit('history', response.history);
          // Close response
          this.close();
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.replyInProgress = false;
      }
    },
    notifyError (error: string) {
      this.$q.notify({
        message: error,
        color: 'red',
        position: 'bottom-right',
        timeout: 2500,
        textColor: 'white',
      });
    },
    updateForm () {
      let message: string = '<div>Hi {{ FIRSTNAME }},</div><div><br></div><div>[YOUR MESSAGE]</div><div><br></div><div>Kind Regards</div><div>{{ LANDLORD_FULLNAME }}</div>';
      message = message.replace(/({{ FIRSTNAME }})/gm, this.contactFirstname);
      message = message.replace(/({{ LANDLORD_FULLNAME }})/gm, this.landlordFullname);

      this.form.message = message;
    },
    close () {
      this.$emit('close');
    },
  },
});
