



























































import Vue from 'vue';
import Loading from '@/blueprint/components/ui/Loading.vue';
import EnquiryMessage from '@/blueprint/components/landlord/enquiry/Enquiry-Message.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';
import { Enquiry, EnquiryMessage as IEnquiryMessage } from '@lordly/models2/interfaces/models/Message';
import { LandlordState } from '@/store/landlord/state';
import { Property } from '@lordly/models2/interfaces/models/Property';

export default Vue.extend({
  name: 'Enquiry-History-Component',
  components: {
    loading: Loading,
    message: EnquiryMessage,
  },
  props: {
    enquiry: {
      type: Object as () => Partial<Enquiry>,
      required: true,
      default: () => {
        return {
          id: '',
          contact: {
            email: '',
          },
          latest_message: '',
          meta: {
            viewed: true,
            lastUpdatedOn: new Date().toISOString(),
            obsolete: false,
          },
        };
      },
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => {
    return {
      historyLoading: false,
      property: {
        address: {
          line1: '',
        },
      } as Partial<Property>,
      history: [] as IEnquiryMessage[],
    };
  },
  computed: {
    validEnquiry (): boolean  {
      if (this.enquiry && this.enquiry.id) {
        return true;
      }
      return false;
    },
    archivedOrNot (): boolean {
      if (this.enquiry && this.enquiry.meta && this.enquiry.meta.obsolete !== undefined) {
        return this.enquiry.meta.obsolete;
      }
      return false;
    },
    userContact (): Record<string, string> {
      const user: LandlordState['user'] = (this.$store.state.landlord as LandlordState).user;
      const response: Record<string, string> = {
        email: user.email,
        firstname: user.firstname,
        surname: user.surname,
      };
      return response;
    },
  },
  watch: {
    enquiry () {
      this.history = [];
      if (this.enquiry && this.enquiry.id) {
        this.getEnquiryHistory();
      }
    },
  },
  created () {
    this.getEnquiryHistory();
  },
  methods: {
    async getEnquiryHistory () {
      if (this.enquiry && this.enquiry.id !== '') {
        // Set loading to true
        this.historyLoading = true;
        // Create query
        const query: GQLTagRequestObject = gql`
          query ($id: String!, $partition: String!) {
            GetEnquiry (
              input: {
                id: $id
                partition: $partition
              }
            ) {
              contact {
                email
                firstname
                surname
              }
              property {
                address {
                  line1
                  city
                }
              }
              history {
                message
                received
                info {
                  from
                }
                meta {
                  createdOn
                }
              }
            }
          }
        `;
        // Create query payload
        const payload: API.GetEnquiryInput = {
          id: this.enquiry.id!,
          partition: (this.$store.state.landlord as LandlordState).user.id,
        };
        // Perform query
        try {
          const response: Partial<Enquiry>  = await this.$gql.Query('GetEnquiry', query, payload);
          // Assign the contact
          this.enquiry.contact = response.contact;
          // Set the history
          this.history = response.history!;
          // Let TS know about the property expand and assign it
          this.property = (response as { property: Partial<Property>}).property;
          // On the next tick of the component scroll to bottom
          this.$nextTick().then(() => {
            // Scroll to the bottom
            const domElement: Element = this.$refs['historyContainer'] as Element;
            domElement.scrollTop = domElement.scrollHeight;
          });
        } catch (e) {
          console.error(e);
        } finally {
          this.historyLoading = false;
        }
      } else {
        this.history = [];
      }
    },
  },
});
