





































import Vue from 'vue';
import CloseBtn from '@/blueprint/components/ui/CloseButton.vue';
import EnquiryList from '@/blueprint/components/landlord/enquiry/Enquiry-List.vue';
import History from '@/blueprint/components/landlord/enquiry/Enquiry-History.vue';
import Reply from '@/blueprint/components/landlord/enquiry/Enquiry-Reply.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { User } from '@lordly/models2/interfaces/models/User';
import { Enquiry } from '@lordly/models2/interfaces/models/Message';
import { API } from '@lordly/models2/interfaces/gql';
import { LandlordState } from '@/store/landlord/state';

export default Vue.extend({
  name: 'Enquiry-Page',
  components: {
    'enquiry-list': EnquiryList,
    'close-btn': CloseBtn,
    'history': History,
    'reply': Reply,
  },
  data () {
    return {
      showRightView: false,
      currentTab: 'Open',
      enquiryListLoading: false,
      enquiryList: [] as Array<Partial<Enquiry>>,
      enquiryFocusedIdx: -1,
      enquiryFocusedLoading: false,
      enquiryPolling: -1,
      enquiryPollCounter: 0,
      component: 'history' as IComponent,
    };
  },
  computed: {
    enquiryType (): string {
      switch (this.currentTab) {
        case 'Open':
          return 'Active';
        case 'Archived':
          return 'Inactive';
        case 'All':
        default:
          return 'All';
      }
    },
    sortedEnquiries (): Array<Partial<Enquiry>> {
      const list: Array<Partial<Enquiry>> = [];
      for (const idx in this.enquiryList) {
        if (this.enquiryList[idx]) {
          const enquiry: Partial<Enquiry> = this.enquiryList[idx];
          if (this.currentTab === 'Open' && !enquiry.meta!.obsolete) {
            list.push(enquiry);
          } else if (this.currentTab === 'Archived' && enquiry.meta!.obsolete) {
            list.push(enquiry);
          } else if (this.currentTab === 'All') {
            list.push(enquiry);
          }
        }
      }
      return list;
    },
    focusedEnquiry (): Partial<Enquiry> {
      return this.sortedEnquiries[this.enquiryFocusedIdx];
    },
  },
  watch: {
    currentTab () {
      this.getEnquiryList();
    },
  },
  // Lifecycle Hooks
  created () {
    this.getEnquiryList();
    this.enablePolling();
  },
  beforeDestroy () {
    this.disablePolling();
  },
  // Methods
  methods: {
    async getEnquiryList () {
      this.enquiryListLoading = true;
      // Create query
      const query: GQLTagRequestObject = gql`
        query ($enquiryType: EnquiryState!) {
          Me {
            enquiries (type: $enquiryType) {
              id
              contact {
                email
              }
              latest_message
              meta {
                viewed
                lastUpdatedOn
                obsolete
              }
            }
          }
        }
      `;
      // Create query payload
      const payload: Record<string, string> = {
        enquiryType: this.enquiryType,
      };
      // Send request
      try {
        const response: Partial<User> = await this.$gql.Query('Me', query, payload);
        this.enquiryList = response.enquiries as unknown as Array<Partial<Enquiry>>;
      } catch (e) {
        console.error(e);
      } finally {
        this.enquiryListLoading = false;
      }
    },
    showMoreInfoForEnquiry (enquiryIdx: number) {
      this.showRightView = true;
      this.enquiryFocusedIdx = enquiryIdx;
      this.disablePolling();
    },
    changeTab (value: string) {
      this.currentTab = value;
      // Reset right view
      if (this.enquiryFocusedIdx > -1) {
        this.enquiryFocusedIdx = -1;
        this.enablePolling();
      }
      // Reset to history
      if (this.component === 'reply') {
        this.component = 'history';
      }
    },
    markEnquiryAsViewed (payload: EnquiryUpdate) {
      // Create update payload
      if (payload && payload.idx > -1 && payload.viewed !== undefined) {
        // Create enquiry
        const enquiry: Partial<Enquiry> = this.enquiryList[payload.idx];
        if (enquiry) {
          // Create API Payload
          const requestPayload: API.UpdateEnquiryInput = {
            id: enquiry.id!,
            partition: (this.$store.state.landlord as LandlordState).user.id,
            enquiry: {
              viewed: true,
            },
          };
          // Send request
          this.updateEnquiry(payload.idx, requestPayload);
        } else {
          console.error('Enquiry not found');
        }
      } else {
        console.error('Payload invalid');
      }
    },
    markEnquiryAsArchivedOrNot () {
      // If we have value
      if (this.focusedEnquiry) {
        // Get enquiry
        const enquiry: Partial<Enquiry> = this.focusedEnquiry;
        // Create API payload
        const requestPayload: API.UpdateEnquiryInput = {
          id: enquiry.id!,
          partition: (this.$store.state.landlord as LandlordState).user.id,
          enquiry: {
            obsolete: !enquiry.meta!.obsolete,
          },
        };
        this.updateEnquiry(this.enquiryFocusedIdx, requestPayload);
      }
      // If open close
      this.showRightView = false;
    },
    async updateEnquiry (idx: number, payload: API.UpdateEnquiryInput) {
      // Set loading to be true
      this.enquiryFocusedLoading = true;
      // Create query
      const query: GQLTagRequestObject = gql`
        mutation ($id: String!, $partition: String!, $enquiry: IEnquiryInfo!) {
          UpdateEnquiry (
            input: {
              id: $id,
              partition: $partition,
              enquiry: $enquiry
            }
          ) {
            id
            contact {
              email
            }
            latest_message
            meta {
              lastUpdatedOn
              viewed
              obsolete
            }
          }
        }
      `;
      // Send Request
      try {
        const response: Partial<Enquiry> = await this.$gql.Mutation('UpdateEnquiry', query, payload);
        // Update enquiry
        if (response) {
          // Set associated value
          if (payload && payload.enquiry && payload.enquiry) {
            if (payload.enquiry.viewed !== undefined) {
              this.focusedEnquiry.meta!.viewed = payload.enquiry.viewed;
            }
            if (payload.enquiry.obsolete !== undefined) {
              this.focusedEnquiry.meta!.obsolete = payload.enquiry.obsolete;
              // Reset right view if obsoleted,
              // BugFix: Also dont reply on focusedEnquiry as its recomputed, use payload instead
              if (payload.enquiry.obsolete && this.enquiryType !== 'All') {
                this.enquiryFocusedIdx = -1;
                this.enablePolling();
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.enquiryFocusedLoading = false;
      }
    },
    showReplyForm () {
      this.component = 'reply';
    },
    closeReplyForm () {
      this.component = 'history';
    },
    enablePolling () {
      this.enquiryPolling = window.setInterval(() => {
        if (this.enquiryPollCounter <= 25) {
          this.getEnquiryList();
        } else {
          this.disablePolling();
        }
      }, 120000);
    },
    disablePolling () {
      window.clearInterval(this.enquiryPolling);
    },
    togglePolling (disable: boolean) {
      if (disable) {
        this.disablePolling();
      } else {
        this.enablePolling();
      }
    },
    closeRightView () {
      this.showRightView = false;
      this.enablePolling();
    },
  },
});

type IComponent = 'history' | 'reply';

export interface EnquiryUpdate {
  idx: number;
  viewed?: boolean;
  obsolete?: boolean;
}

export interface SortedEnquiryPair {
  idx: number;
  enquiry: Partial<Enquiry>;
}
