



















import Vue from 'vue';
import { date } from 'quasar';
import { EnquiryUpdate } from '@/blueprint/pages/landlord/Enquiry.vue';

export default Vue.extend({
  name: 'Enquiry-Row-Component',
  props: {
    enquiry: {
      type: Object,
      required: false,
      default: () => {
        return {
          id: '',
          contact: {
            email: '',
          },
          latest_message: '',
          meta: {
            lastUpdatedOn: '',
            viewed: false,
            obsolete: false,
          },
        };
      },
    },
    idx: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  data: () => {
    return {};
  },
  methods: {
    viewEnquiry () {
      this.$parent.$emit('focus', this.idx);
      this.markAsViewed();
    },
    markAsViewed () {
      if (!this.enquiry.meta.viewed) {
        const payload: EnquiryUpdate = {
          idx: this.idx,
          viewed: true,
        };
        this.$parent.$emit('viewed', payload);
      }
    },
    getPrettyDate (value: string) {
      return date.formatDate(new Date(value), 'Do MMMM YYYY • HH:mm');
    },
  },
});
