






































import Vue from 'vue';
import EnquiryRow from '@/blueprint/components/landlord/enquiry/Enquiry-Row.vue';
import Loading from '@/blueprint/components/ui/Loading.vue';

export default Vue.extend({
  name: 'Enquiry-List-Component',
  components: {
    'enquiry-row': EnquiryRow,
    'loading': Loading,
  },
  props: {
    currentTab: {
      type: String,
      default: 'Open',
      required: true,
    },
    loadingEnquiryList: {
      type: Boolean,
      default: false,
      required: true,
    },
    enquiryList: {
      type: Array,
      default: () => {
        return [];
      },
      required: false,
    },
    enquiryFocusedIdx: {
      type: Number,
      default: -1,
      required: true,
    },
  },
  data: () => {
    return {
      tabOptions: [
        {
          label: 'Archived',
        },
        {
          label: 'Open',
        },
        {
          label: 'All',
        },
      ],
    };
  },
  computed: {
    contextTab: {
      get (): string {
        return this.currentTab;
      },
      set (value: string) {
        this.$emit('tabChanged', value);
      },
    },
  },
});
